import {v2Request} from './v2Request';


export const userResourceLogV2Api = {

    async add({targetType, targetId, operation}) {
        return v2Request.post('userResourceLog/add', {targetType, targetId, operation});
    }

};
