import {v2Request} from "./v2Request";

export const bizResourceV2Api = {

  // 保存我的需求
  async submit(model) {
    return v2Request.post(`bizResource/submit`, model);
  },

  // 我的需求分页查询
  async findMyPage(model) {
    return v2Request.post(`bizResource/findMyPage`, model);
  },


  // 我的需求分页查询
  async findMyCollect(model) {
    return v2Request.post(`bizResource/findMyCollect`, model);
  },


  // 更新我的需求状态
  async updateMyResourceStatus(model) {
    return v2Request.post(`bizResource/updateMyResourceStatus`, model);
  },

  // id查找
  async findById(id) {
    const res = await v2Request.post(`bizResource/findById`, {id});
    res.expireDate = res.expireDate.slice(0,10);
    return res;
  },

  // 搜索
  async search(model) {
    return v2Request.post(`bizResource/search`, model);
  },


  // 切换收藏状态
  async toggleCollect(bizResourceId) {
    return v2Request.post(`bizResource/toggleCollect`, {bizResourceId});
  },


  async findMyCollectCount(model) {
    return v2Request.post(`bizResource/findMyCollectCount`, model);
  },

};
