import {v2Request} from "./v2Request";

export const needsV2Api = {

  // 保存我的需求
  async submit(model) {
    return v2Request.post(`needs/submit`, model);
  },

  // 我的需求分页查询
  async findMyPage(model) {
    return v2Request.post(`needs/findMyPage`, model);
  },


  // 我的需求分页查询
  async findMyCollect(model) {
    return v2Request.post(`needs/findMyCollect`, model);
  },


  // 更新我的需求状态
  async updateMyNeedsStatus(model) {
    return v2Request.post(`needs/updateMyNeedsStatus`, model);
  },

  // id查找
  async findById(id) {
    const res = await v2Request.post(`needs/findById`, {id});
    if(res.expireDate) {
      res.expireDate = res.expireDate.slice(0, 10);
    }
    return res;
  },

  // 搜索
  async search(model) {
    return v2Request.post(`needs/search`, model);
  },

  // 切换收藏状态
  async toggleCollect(needsId) {
    return v2Request.post(`needs/toggleCollect`, {needsId});
  },


  async findMyCollectCount() {
    return v2Request.post(`needs/findMyCollectCount`);
  },


};
