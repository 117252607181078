<template>
  <div class="needs-card">
    <div class="needs-card-identity">
      <div class="needs-card-line-right needs-company">{{item.company}}</div>
      <div class="needs-card-line-left">
        <template v-if="item.identityType===1">品牌方</template>
        <template v-else-if="item.identityType===2">服务商</template>
        <template v-else-if="item.identityType===3">制作商</template>
        <template v-else-if="item.identityType===4">流量方</template>
        <template v-else-if="item.identityType===5">场地方</template>
        <template v-else-if="item.identityType===6">从业者</template>
      </div>
    </div>
    <div class="needs-card-line first-line">
      <div class="needs-card-line-left">
        <!--{name: '需求类型', value: null},-->
        <!--{name: '找服务商', value: 1},-->
        <!--{name: '找制作商', value: 2},-->
        <!--{name: '异业合作', value: 3},-->
        <!--{name: '找人', value: 4},-->
        <!--{name: '找其他', value: 5},-->
        <template v-if="item.needsType===1">找服务商</template>
        <template v-else-if="item.needsType===2">找制作商</template>
        <template v-else-if="item.needsType===3">异业合作</template>
        <template v-else-if="item.needsType===4">找人</template>
        <template v-else-if="item.needsType===5">找其他</template>
      </div>
      <div class="needs-card-line-right">{{item.desc}}</div>
    </div>
    <div class="needs-card-line">
      <div class="needs-card-line-left">
        <!--{name: '回报类型', value: null},-->
        <!--{name: '付费', value: 1},-->
        <!--{name: '资源置换', value: 2},-->
        <!--{name: '视资源而定', value: 3},-->
        <template v-if="item.rewardType===1">付费</template>
        <template v-else-if="item.rewardType===2">资源置换</template>
        <template v-else-if="item.rewardType===3">视资源而定</template>
      </div>
      <div class="needs-card-line-right">{{item.rewardDesc}}</div>
    </div>

    <div class="needs-card-line" v-if="(item.province && item.city) || item.cityDesc">
      <div class="needs-card-line-left">
        <template v-if="item.province && item.city">
          <van-icon name="location-o"/>
          {{item.city}}
        </template>
      </div>
      <div class="needs-card-line-right" v-if="item.cityDesc">{{item.cityDesc}}</div>
      <div class="needs-card-line-right" v-else>{{item.province}} - {{item.city}}</div>
    </div>

    <div class="needs-card-line">
      <div class="needs-card-line-left">
        <img src="@/assets/svg/calendar.svg"
             style="height: 11px;width: 11px;margin-right: 2px;transform:translateY(-1px)">
        截止日期
      </div>
      <div class="needs-card-line-right">{{item.expireDate|date}} 结束</div>
    </div>

    <div class="needs-card-line">
      <div class="needs-card-line-left">联系人</div>
      <div class="needs-card-line-right" v-if="item.contact&&item.contact.length">{{item.contact}}</div>
      <div v-if="item.userId" class="needs-card-line-right-handler" @click="goUser"><img
        src="@/assets/svg/user_home.svg">主页
      </div>
    </div>
    <div class="needs-card-line">
      <div class="needs-card-line-left">联系号码</div>
      <div class="needs-card-line-right has-handler" v-if="item.mobile&&item.mobile.length">
        <div>{{item.mobile|mobile}}</div>
        <a @click="getMobile" href="javascript:void(0)" class="needs-card-line-right-handler"><img
          src="@/assets/svg/call.svg">拨打</a>
      </div>
    </div>
    <div class="needs-card-line">
      <div class="needs-card-line-left">微信号</div>
      <div class="needs-card-line-right has-handler" v-if="item.wxNum && item.wxNum.length">
        <div>{{item.wxNum|wxNum}}</div>
        <div class="needs-card-line-right-handler" @click="copyWxNum"><img src="@/assets/svg/wx.svg">复制</div>
      </div>
    </div>


    <div class="needs-card-line"
         v-if="item.introduceFileName && item.introduceFileName.length && item.introduceFileUrl && item.introduceFileUrl">
      <div class="needs-card-line-left">介绍文件</div>
      <a :href="item.introduceFileUrl" target="_blank" class="needs-card-line-right">{{item.introduceFileName}}</a>
    </div>
    <div class="needs-card-line" v-if="item.imgUrls && item.imgUrls.length">
      <div class="needs-card-line-left">附图</div>
      <div class="needs-card-line-right">
        <div class="needs-card-line-img" v-for="(url,index) in imgUrlArr" @click="previewImage(index)">
          <img :src="url" style="height: 65px;width: 65px;" alt>
        </div>
      </div>
    </div>


    <div class="needs-dynamic">
      <div class="needs-date">
        {{item.createTime|date}}
      </div>
      <div class="needs-tip" v-if="!hideTip">
        <img src="@/assets/svg/tip.svg"/>
        请自行甄别信息风险
      </div>
      <div class="needs-dynamic-btns">
        <div class="needs-dynamic-btn" v-if="showCollect" @click="toggleCollect">
          <img class="collect-icon" v-if="!item.hasCollect" src="@/assets/svg/needs_collect.svg"/>
          <img class="collect-icon" v-else src="@/assets/svg/needs_collect_active.svg"/>
        </div>
        <div class="needs-dynamic-btn" v-if="item.status===0 && item.auditStatus === 1" @click="onShare">
          <i class="iconfont icon-share"></i>
        </div>
      </div>
    </div>


    <div v-if="showEdit" class="needs-edit">
      <div @click="$emit('edit',item)" class="needs-edit-item">编辑
        <van-icon name="edit"/>
      </div>
      <div class="needs-edit-item" v-if="item.auditStatus===1" @click="visibles.statusAction=true">
        <template v-if="item.status===0">展示中</template>
        <template v-if="item.status===1">已关闭</template>
        <van-icon name="arrow-down"/>
      </div>
      <div class="needs-edit-item" v-else>
        <block v-if="item.auditStatus===0">审核中</block>
        <block v-else-if="item.auditStatus===10">
          <div @click="showRejectReason">
            已驳回 <van-icon name="warning" color="#ee4a50"/>
          </div>
        </block>
      </div>
      <!--<van-action-sheet show="{{ statusSheetVisible " bind:close="hideStatusSheet" actions="{{ statusOptions " bind:select="updateStatus"></van-action-sheet>-->
    </div>


    <van-action-sheet v-model="visibles.statusAction" cancel-text="取消" :actions="[
    {name:'展示中',value:0},
    {name:'已关闭',value:1}
    ]" @select="updateStatus"/>
  </div>
</template>


-------------------- script --------------------

<script>
  import {copyToClipBoard} from "../../config/util";
  import {needsV2Api} from "../../api/v2/needsV2Api";
  import {ImagePreview} from 'vant';
  import {userResourceLogV2Api} from "../../api/v2/userResourceLogV2Api";
  import {mapGetters} from 'vuex'
  import {bizResourceV2Api} from "../../api/v2/bizResourceV2Api";

  export default {
    props: {
      item: {
        type: Object
      },
      hideTip: {
        type: Boolean,
        required: false
      },
      showEdit: {
        type: Boolean,
        required: false
      },
      showCollect:{
        type:Boolean
      }
    },

    computed: {
      ...mapGetters(['userInfo']),
      imgUrlArr() {
        if (!this.item || !this.item.imgUrls || !this.item.imgUrls.length) {
          return [];
        }
        return JSON.parse(this.item.imgUrls) || [];
      }
    },


    data() {
      return {
        visibles: {
          statusAction: false
        }
      }
    },


    methods: {

      getMobile() {
        if(!Object.keys(this.userInfo).length){
          return this.$store.commit('SHOW_LOGIN_DIALOG');
        }
        userResourceLogV2Api.add({
          targetType: 'needs',
          targetId: this.item.id,
          operation: 'getMobile'
        });
        window.location.href = 'tel:' + this.item.mobile
      },

      // 前往用户主页
      goUser() {
        this.$router.push('/otherPerson/' + this.item.userId);
      },


      copyWxNum() {
        if(!Object.keys(this.userInfo).length){
          return this.$store.commit('SHOW_LOGIN_DIALOG');
        }
        copyToClipBoard(this.item.wxNum);
        userResourceLogV2Api.add({
          targetType: 'needs',
          targetId: this.item.id,
          operation: 'getWxNum'
        });
        this.$toast.success('复制成功!');
      },


      async updateStatus(e) {
        try {
          await needsV2Api.updateMyNeedsStatus({
            id: this.item.id,
            status: e.value
          });
          this.$toast.success('操作成功');
          this.visibles.statusAction = false;
          this.$emit('updateStatus', e.value);
        } catch (e) {
          this.$toast.error('操作失败');
        }
      },


      // 预览
      previewImage(index) {
        if (this.imgUrlArr && this.imgUrlArr.length) {
          ImagePreview({
            images: this.imgUrlArr,
            startPosition: index
          });
        }
      },


      showRejectReason(){
        this.$notify({
          message: this.item.rejectReason,
          color: '#ad0000',
          background: '#ffe1e1',
        });
      },


      async toggleCollect(){
        await needsV2Api.toggleCollect(this.item.id)
        this.item.hasCollect = !this.item.hasCollect
      },


      onShare(){
        const token = localStorage.getItem('token')
        this.$store.commit('previewSingleImage/show',{
          src:`https://www.001ppt.com/api/applet/v2/needs/sharePost/${this.item.id}.jpg?Token=${token}&token=${token}`
        })
      }

    }
  }
</script>


-------------------- style --------------------

<style scoped lang="less">
  .needs-card {
    background: #fff;
    margin: 12px 24px 0 24px;
    border-radius: 6px;
  }

  .needs-card-identity {
    display: flex;
    border-bottom: 1px solid rgba(21, 29, 54, 0.08);
    padding: 14px 14px 8px 14px;
  }

  .needs-company {
    font-weight: bolder;
  }

  .needs-card-identity .needs-card-line-left {
    font-size: 12px;
    color: rgba(21, 29, 54, 0.3);
    padding: 2px 10px;
    margin-right: 0;
    width: auto;
    height: auto;
  }

  .needs-card-line {
    display: flex;
    align-items: flex-start;
    padding: 7px 16px 7px 14px;
    font-size: 12px;
  }

  .needs-card-line.first-line {
    padding: 10px 16px 10px 14px;
    border-bottom: 1px solid rgba(21, 29, 54, 0.08);
  }

  .needs-card-line-left {
    width: 80px;
    font-size: 12px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 1000px;
    color: rgba(80, 80, 80, 0.3);
    background-color: rgba(21, 29, 54, 0.05);
  }

  .needs-card-line-right {
    flex-shrink: 0;
    flex-grow: 1;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100px;
    white-space: pre-line;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .has-handler {
    justify-content: space-between;
  }

  .needs-card-line-right-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 60px;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(21, 29, 54, 0.05);
    font-size: 10px;
    border-radius: 60px;
  }

  .needs-card-line-right-handler img {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }

  .needs-edit {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
  }

  .needs-edit-item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 14px 0;
  }


  .needs-card-line-tag {
    flex-wrap: wrap;
    padding: 0 0 8px 0;
  }

  .needs-card-line-tag-item {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    background: #f2f2f2;
    border-radius: 2px;
    margin-right: 4px;
    margin-bottom: 4px;
  }


  .needs-dynamic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 7px;
    padding: 14px 14px 14px 16px;
    border-top: 1px solid rgba(21, 29, 54, 0.08);
  }

  .needs-date {
    opacity: 0.3;
    color: rgba(21, 29, 54, 1);
  }

  .needs-dynamic-btns {
    display: flex;
    align-items: center;
  }

  .needs-dynamic-btn {
    margin-right: 14px;
  }

  .needs-dynamic-btn text {
    font-size: 20px;
  }

  .icon-collect_active {
    color: #e1494e
  }

  .needs-tip {
    font-size: 10px;
    display: flex;
    align-items: center;
  }

  .needs-tip img {
    height: 12px;
    width: 12px;
    margin-right: 1px;
  }


  .needs-card-line-city {

  }

  .collect-icon {
    height: 20px;
    width: 20px;
  }

</style>
